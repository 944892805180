
import { Component, Vue } from 'vue-property-decorator'
import {
    apiFaceSheetTemplateEdit,
    apiFaceSheetTemplateDetail,
    apiFaceSheetTemplateAdd,
    apiExpressList,
    apiGetFaceSheetPayment
} from '@/api/setting'
@Component
export default class FaceSheetEdit extends Vue {
    /** S Data **/

    identity: Number = 1

    status: any = ''

    freightPayment: Array<Object> = []

    expressData: Array<Object> = []

    // 打印机设置的数据
    form: any = {
        name: '', //	是	string	模板名称
        template_id: '', //	是	string	模板ID
        express_id: '', //	是	int	快递公司
        partner_id: '', //	是	string	电子面单账号
        partner_key: '', //	是	string	电子面单密码
        net: '', //	是	string	网点
        pay_type: '' //	是	int	运费支付方式
    }

    // 表单验证
    rules: any = {
        name: [{ required: true, message: '请输入模版名称', trigger: 'blur' }],
        template_id: [{ required: true, message: '请输入模版编号', trigger: 'blur' }],
        express_id: [{ required: true, message: '请选择快递公司', trigger: 'change' }],
        net: [{ required: true, message: '请输入收件网点标示', trigger: 'blur' }],
        pay_type: [{ required: true, message: '请选择运费支付方式', trigger: 'change' }]
    }

    /** E Data **/

    /** S Methods **/

    // 获取打印机类型数据
    async getFaceSheetDetail() {
        const res = await apiFaceSheetTemplateDetail({ id: this.identity })
        this.form = res
    }

    getFreightPaymentFunc() {
        apiGetFaceSheetPayment({})
            .then((res: any) => {
                this.freightPayment = res
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    // 获取小票模版数据
    getExpressDataFunc() {
        apiExpressList({})
            .then((res: any) => {
                this.expressData = res
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            if (!this.identity) {
                this.handleFaceSheetAdd()
            } else {
                this.handleFaceSheetEdit()
            }
        })
    }

    handleFaceSheetAdd() {
        const params = this.form
        apiFaceSheetTemplateAdd({ ...params }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 编辑打印机
    handleFaceSheetEdit() {
        const params = this.form
        const id: number = this.identity as number
        apiFaceSheetTemplateEdit({ ...params, id }).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    newExpress() {
        const text = this.$router.resolve('/setting/delivery/express')
        window.open(text.href, '_blank')
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.identity = query.id
        this.getFreightPaymentFunc()
        this.getExpressDataFunc()
        if (this.identity) {
            this.getFaceSheetDetail()
        }
    }
    /** E Life Cycle **/
}
